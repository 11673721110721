// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-island-albums-js": () => import("./../../../src/pages/island/albums.js" /* webpackChunkName: "component---src-pages-island-albums-js" */),
  "component---src-pages-island-books-js": () => import("./../../../src/pages/island/books.js" /* webpackChunkName: "component---src-pages-island-books-js" */),
  "component---src-pages-island-movies-js": () => import("./../../../src/pages/island/movies.js" /* webpackChunkName: "component---src-pages-island-movies-js" */),
  "component---src-pages-leftover-excerpts-js": () => import("./../../../src/pages/leftover/excerpts.js" /* webpackChunkName: "component---src-pages-leftover-excerpts-js" */),
  "component---src-pages-leftover-latinquotes-js": () => import("./../../../src/pages/leftover/latinquotes.js" /* webpackChunkName: "component---src-pages-leftover-latinquotes-js" */),
  "component---src-pages-leftover-poems-js": () => import("./../../../src/pages/leftover/poems.js" /* webpackChunkName: "component---src-pages-leftover-poems-js" */),
  "component---src-pages-leftover-quotes-js": () => import("./../../../src/pages/leftover/quotes.js" /* webpackChunkName: "component---src-pages-leftover-quotes-js" */),
  "component---src-pages-library-collections-js": () => import("./../../../src/pages/library/collections.js" /* webpackChunkName: "component---src-pages-library-collections-js" */),
  "component---src-pages-library-stories-js": () => import("./../../../src/pages/library/stories.js" /* webpackChunkName: "component---src-pages-library-stories-js" */),
  "component---src-pages-maradek-js": () => import("./../../../src/pages/maradek.js" /* webpackChunkName: "component---src-pages-maradek-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-excerpt-js": () => import("./../../../src/templates/excerpt.js" /* webpackChunkName: "component---src-templates-excerpt-js" */),
  "component---src-templates-movie-js": () => import("./../../../src/templates/movie.js" /* webpackChunkName: "component---src-templates-movie-js" */),
  "component---src-templates-poem-js": () => import("./../../../src/templates/poem.js" /* webpackChunkName: "component---src-templates-poem-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

