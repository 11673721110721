import React from "react"
import { RiAlbumFill } from "react-icons/ri"
import { GiLaurels, GiBookshelf } from "react-icons/gi"
import { BsCardText, BsFileText, BsFillCollectionFill } from "react-icons/bs"
import { GrBlockQuote } from "react-icons/gr"
import { IoDocumentTextSharp } from "react-icons/io5"
import { MdLocalMovies } from "react-icons/md"

export default [
  {
    page: "Maradék",
    label: "Napi latin",
    url: `/leftover/latinquotes/1`,
    icon: <GiLaurels className="icon" />,
  },
  {
    page: "Maradék",
    label: "Idézetek",
    url: `/leftover/quotes/1`,
    icon: <GrBlockQuote className="icon" />,
  },
  {
    page: "Maradék",
    label: "Versek",
    url: `/leftover/poems/1`,
    icon: <BsCardText className="icon" />,
  },
  {
    page: "Maradék",
    label: "Egypercesek",
    url: `/leftover/excerpts/1`,
    icon: <BsFileText className="icon" />,
  },
  {
    page: "Könyvtár",
    label: "Komplett művek",
    url: `/library/collections/1`,
    icon: <BsFillCollectionFill className="icon" />,
  },
  {
    page: "Könyvtár",
    label: "Novellák",
    url: `/library/stories/1`,
    icon: <IoDocumentTextSharp className="icon" />,
  },
  {
    page: "Sziget",
    label: "1001 könyv",
    url: `/island/books/1`,
    icon: <GiBookshelf className="icon" />,
  },
  {
    page: "Sziget",
    label: "1001 film",
    url: `/island/movies/1`,
    icon: <MdLocalMovies className="icon" />,
  },
  {
    page: "Sziget",
    label: "1001 lemez",
    url: `/island/albums/1`,
    icon: <RiAlbumFill className="icon" />,
  },
]
