import React from "react"
import { createGlobalStyle } from "styled-components"
import { GatsbyProvider } from "./src/context/context"

// provider

// import { GatsbyProvider } from "./src/context/context"
const GlobalStyle = createGlobalStyle`

/* New main colors form hero:

black: #000000
brown: #2A1919   rgb(42, 25, 25)  hsl(0, 25%, 13%)
red: #84060B     rgb(132, 6, 11)  hsl(358, 91% 27%)
bright-red: #E02C3E rgb(224, 44, 62) hsl (354, 74, 52)
grey: #484747   rgb(72, 71, 71) hsl(0, 1%, 28%)
light: #c1c1c1   rgb(193,193,193) hsl(0, 0, 76%)

rose: #cf0911

https://palettegenerator.com/

https://www.w3schools.com/colors/colors_hexadecimal.asp

https://www.colorhexa.com/84060b

*/




:root {
  /* dark shades of primary brown color*/
  --clr-primary-1: hsl(0, 24%, 1%);
  --clr-primary-2: hsl(0, 25%, 2%);
  --clr-primary-3: hsl(0, 26%, 5%);
  --clr-primary-4: hsl(0, 25%, 9%);
  /* primary/main color */
  --clr-primary-5: hsl(0, 25%, 13%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(0, 25%, 17%);
  --clr-primary-7: hsl(0, 25%, 21%);
  --clr-primary-8: hsl(0, 25%, 25%);
  --clr-primary-9: hsl(0, 25%, 28%);
  --clr-primary-10: hsl(0, 24%, 32%);
   /* button text color #aa7171 */


  --clr-primary-20: hsl(0, 24%, 48%);
  --clr-primary-21: hsl(0, 25%, 52%);
  --clr-primary-22: hsl(0, 25%, 55%);

  /* dark shades of red color*/
  --clr-red-1: hsl(358, 90%, 12%);
  --clr-red-2: hsl(358, 92%, 15%);
  --clr-red-3: hsl(357, 92%, 19%);
  --clr-red-4: hsl(358, 91%, 23%);
  /* primary red color */
  --clr-red-5: hsl(358, 91%, 27%);
  /* lighter shades of red color */
  --clr-red-6: hsl(357, 91%, 31%);
  --clr-red-7: hsl(358, 91%, 35%);
  --clr-red-8: hsl(358, 91%, 39%);
  --clr-red-9: hsl(358, 92%, 42%);
  --clr-red-10: hsl(358, 91%, 50%);


  /* dark shades of bright red color*/
  --clr-red-1: hsl(354, 74%, 33%);
  --clr-red-2: hsl(354, 75%, 37%);
  --clr-red-3: hsl(354, 74%, 41%);
  --clr-red-4: hsl(354, 75%, 45%);
  /* primary bright red color */
  --clr-red-5: hsl(354, 74%, 52%);
  /* lighter shades of bright red color */
  --clr-red-6: hsl(354, 75%, 56%);
  --clr-red-7: hsl(354, 74%, 60%);
  --clr-red-8: hsl(354, 75%, 64%);
  --clr-red-9: hsl(354, 74%, 68%);
  --clr-red-10: hsl(354, 45%, 93%);


  /* dark grey - used for headings */
  --clr-grey-1: hsl(0, 0%, 12%);
  --clr-grey-2: hsl(0, 0%, 16%);
  --clr-grey-3: hsl(0, 0%, 20%);
  --clr-grey-4: hsl(0, 1%, 24%);
  /* grey used for paragraphs */
  /* primary dark grey color*/
  --clr-grey-5: hsl(0, 1%, 28%);
  /* lighter shades of dark grey color */
  --clr-grey-6: hsl(0, 1%, 32%);
  --clr-grey-7: hsl(0, 1%, 36%);
  --clr-grey-8: hsl(0, 1%, 40%);
  --clr-grey-9: hsl(0, 1%, 43%);
  --clr-grey-10: hsl(0, 1%, 51%);


  /* dark shades of light grey */
  --clr-light-1: hsl(0, 0%, 60%);
  --clr-light-2: hsl(0, 0%, 64%);
  --clr-light-3: hsl(0, 0%, 68%);
  --clr-light-4: hsl(0, 0%, 72%);
  /* primary light grey color*/
  --clr-light-5: hsl(0, 0%, 76%);
  /* lighter shades of light grey color */
  --clr-light-6: hsl(0, 0%, 80%);
  --clr-light-7: hsl(0, 0%, 84%);
  --clr-light-8: hsl(0, 0%, 87%);
  --clr-light-9: hsl(0, 0%, 91%);
  --clr-light-10: hsl(0, 0%, 95%);


  --clr-white: #fff;
  --clr-red-dark: hsl(358, 91%, 27%);
  --clr-red-light: hsl(354, 74%, 52%);
  /* --clr-green-dark: hsl(125, 67%, 44%); */
  /* --clr-green-light: hsl(125, 71%, 66%); */
  --clr-black: #000;


  --ff-primary: "Roboto", sans-serif;
  /* --ff-primary: "Lato", sans-serif; */
  --ff-secondary: "Open Sans", sans-serif;
  ${"" /* --ff-secondary: "Lato", sans-serif; */}


  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
  font-weight: 400;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    ${"" /* font-size: 1.75rem; */}
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-22);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  ${"" /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */}
  border-radius: var(--radius);
}
.btn:hover {
  background: var(--clr-primary-20);
  color: var(--clr-primary-1);
}

/* section */
.section {
  padding: 5rem 0;
  position: relative;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}


`
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      <GatsbyProvider>{element}</GatsbyProvider>
    </>
  )
}
